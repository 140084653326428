@import url("https://fonts.googleapis.com/css2?family=Merriweather:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;600;700&display=swap");
@import "./assets/style/variables.scss";
@import "./components/navbar/Navbar.scss";
@import "./components/homepage/Homepage.scss";
@import "./components/offcanvas/OffCanvas.scss";
@import "./components/homepage/about_me/AboutMe.scss";
@import "./components/homepage/biography/Biography.scss";
@import "./components/homepage/news/News.scss";
@import "./components/homepage/plays_and_contact/PlaysAndContact.scss";
@import "./components/footer/Footer.scss";
@import "./components/about_me_page/AboutMePage.scss";
@import "./components/gallery_page/GalleryPage.scss";
@import "./components/loading/Loading.scss";
@import "./components/error_page/NotFoundPage.scss";

html {
  position: relative;
}

html,
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: $main-font;

  .app-container {
    min-height: 80vh;
  }

  .on-loading {
    background-color: black;
  }

  .btn.btn-outline-dark {
    &:hover {
      background-color: black !important;
    }

    &:active {
      background-color: black !important;
    }

    &:focus-visible {
      background-color: black !important;
    }
  }

  .btn {
    border-radius: 10px !important;
  }
}

/* Works on Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: lightgray gray;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 10px;
}

*::-webkit-scrollbar-track {
  background: black;
}

*::-webkit-scrollbar-thumb {
  background-color: lightgray;
  border-radius: 20px;
  border: 2px solid black;
  -webkit-box-shadow: inset 0px 0px 10px 0px rgba(62, 62, 62, 1);
  -moz-box-shadow: inset 0px 0px 10px 0px rgba(62, 62, 62, 1);
  box-shadow: inset 0px 0px 10px 0px rgba(62, 62, 62, 1);
}
