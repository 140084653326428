.about-me-top-row {
  margin-top: 66px;
  background-color: black;

  h2 {
    color: $white;
    font-family: $main-font;
    font-size: 2em;
    text-transform: uppercase;

    @media screen and (min-width: 450px) {
      font-size: 2.5em;
    }

    @media screen and (min-width: 768px) {
      font-size: 2.8em;
    }

    @media screen and (min-width: 1200px) {
      font-size: 3.2em;
    }
  }
}

.bio-about-me {
  margin-bottom: 50px;
}

.about-me-container {
  margin-top: 50px;

  @media screen and (min-width: 768px) {
    margin-top: 80px;
    margin-bottom: 80px;
  }

  @media screen and (min-width: 1200px) {
    margin-top: 125px;
    margin-bottom: 125px;
  }

  .about-me-img {
    height: 300px;
    width: 100%;
    background-image: url("../../assets/images/Photos/About/About-me-LowRes.jpg");
    background-position: center top;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: $main_radius;
    -webkit-box-shadow: 10px 5px 11px -8px rgba(121, 121, 121, 1);
    -moz-box-shadow: 10px 5px 11px -8px rgba(121, 121, 121, 1);
    box-shadow: 10px 5px 11px -8px rgba(121, 121, 121, 1);

    @media screen and (min-width: 340px) {
      height: 380px;
    }

    @media screen and (min-width: 415px) {
      height: 450px;
    }

    @media screen and (min-width: 465px) {
      height: 515px;
    }

    @media screen and (min-width: 768px) {
      height: 450px;
    }

    @media screen and (min-width: 1200px) {
      height: 650px;
    }
  }

  .col-bio {
    h4 {
      font-size: 1.8em;
      font-weight: 600;
      font-family: $main-font;

      @media screen and (min-width: 1400px) {
        font-size: 2.2em;
      }
    }

    .actual-about-text {
      font-size: 0.9em;

      @media screen and (min-width: 415px) {
        font-size: 0.95em;
      }

      @media screen and (min-width: 576px) {
        font-size: 1em;
      }

      @media screen and (min-width: 768px) {
        font-size: 0.92em;
      }

      @media screen and (min-width: 992px) {
        font-size: 0.95em;
      }

      @media screen and (min-width: 1200px) {
        font-size: 1.15em;
      }

      @media screen and (min-width: 1400px) {
        font-size: 1.25em;
      }
    }

    .actual-about-text.italiano {
      @media screen and (min-width: 768px) {
        font-size: 0.9em;
      }
      @media screen and (min-width: 992px) {
        font-size: 0.95em;
      }

      @media screen and (min-width: 1200px) {
        font-size: 1.15em;
      }

      @media screen and (min-width: 1400px) {
        font-size: 1.25em;
      }
    }
  }
}

.bio-by-year-row {
  margin-top: 75px;

  h3 {
    font-size: 2em;
    font-weight: 600;
  }

  .bio-by-year {
    .single-year {
      @media screen and (min-width: 768px) {
        margin-top: 40px;
      }

      h6 {
        font-size: 1.2em;
        font-weight: 600;
        width: 20%;
        max-width: 55px;
      }

      .small-row {
        width: 75px;
        height: 1px;
        background-color: $black;
        -webkit-transition: width 0.25s ease-out;
        -moz-transition: width 0.25s ease-out;
        -ms-transition: width 0.25s ease-out;
        -o-transition: width 0.25s ease-out;
        transition: width 0.25s ease-out;
      }

      .single-exp {
        font-size: 0.8em;
        padding-left: 70px;
      }

      &:hover {
        .small-row {
          width: 70%;

          @media screen and (min-width: 400px) {
            width: 80%;
          }

          @media screen and (min-width: 768px) {
            width: 90%;
          }
        }
      }
    }
  }
}

.about-me-container,
.bio-by-year-row {
  .text-bold {
    font-weight: bold;
  }
}

// form section

.about-me-container {
  .actual-form {
    margin-top: 45px;
    border-radius: $main_radius;
    background-color: $form_bg;
    -webkit-box-shadow: 5px 5px 11px -8px rgba(121, 121, 121, 1);
    -moz-box-shadow: 5px 5px 11px -8px rgba(121, 121, 121, 1);
    box-shadow: 5px 5px 11px -8px rgba(121, 121, 121, 1);

    .form-control {
      background-color: $form_bg;
      border: none;
      border-radius: 0;
      border-bottom: 1px solid $light_gray;
    }

    .btn {
      font-size: 0.85em;
      border-radius: 0;
    }
  }
}
