.gallery-title-row {
  margin-top: 84px;
  background-color: $black;
  text-align: center;
  transition: opacity 0.8s;

  h2 {
    color: $white;
    font-size: 2em;
    text-transform: uppercase;
    font-weight: 500;

    @media screen and (min-width: 450px) {
      font-size: 2.5em;
    }

    @media screen and (min-width: 768px) {
      font-size: 2.8em;
    }

    @media screen and (min-width: 1200px) {
      font-size: 3.2em;
    }
  }
}

.gallery-title-row.go-hidden,
.swiper-boot-container.go-hidden {
  opacity: 0;
  z-index: -1;
  transition: opacity 0.5s;
}

.swiper-boot-container {
  transition: opacity 0.8s;

  .social-row {
    width: 100%;
  }

  .gallery-homepage-btn {
    border-radius: 0;
    text-transform: uppercase;
    font-size: 0.7em;
    margin-top: 75px;
  }
}

.mySwiper,
.mySwiper2 {
  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: $white;

    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .swiper-slide {
    background-size: contain;
    background-position: center;
  }

  .swiper-wrapper {
    display: flex;
    align-items: center;
  }
}

.swiper-boot-container {
  .swiper {
    width: 100%;
    height: auto;
    margin-left: auto;
    margin-right: auto;

    @media screen and (min-width: 992px) {
      width: 85%;
    }

    @media screen and (min-width: 1200px) {
      width: 74%;
    }

    @media screen and (min-width: 1600px) {
      width: 85%;
    }

    @media screen and (min-width: 2000px) {
      width: 90%;
    }
  }
}

.mySwiper {
  height: 20%;
  box-sizing: border-box;
  padding: 10px 0;

  .swiper-slide {
    margin-top: 10px;
    width: 25%;
    height: 50px;
    opacity: 0.4;
    overflow: hidden;

    &:hover {
      cursor: pointer;
    }

    @media screen and (min-width: 370px) {
      height: 60px;
    }

    @media screen and (min-width: 450px) {
      height: 90px;
    }

    @media screen and (min-width: 768px) {
      height: 100px;
    }

    @media screen and (min-width: 992px) {
      height: 125px;
    }

    @media screen and (min-width: 2000px) {
      height: 150px;
    }
  }
}

.mySwiper .swiper-slide-thumb-active {
  opacity: 1;
}

.mySwiper2 {
  background-color: $black !important;

  @media screen and (min-width: 768px) {
    .swiper-button-prev {
      padding: 45px;
      padding-left: 20px;
      -webkit-transition: color 0.2s ease-in;
      -moz-transition: color 0.2s ease-in;
      -ms-transition: color 0.2s ease-in;
      -o-transition: color 0.2s ease-in;
      transition: color 0.2s ease-in;

      &:hover {
        color: $white !important;
      }
    }

    .swiper-button-next {
      padding: 45px;
      padding-right: 20px;
      -webkit-transition: color 0.2s ease-in;
      -moz-transition: color 0.2s ease-in;
      -ms-transition: color 0.2s ease-in;
      -o-transition: color 0.2s ease-in;
      transition: color 0.2s ease-in;

      &:hover {
        color: $white !important;
      }
    }
  }
}

:root {
  --swiper-theme-color: gray !important;
}

.image-focus {
  position: absolute;
  width: 100%;
  height: 100vh;
  opacity: 0;
  background-color: $black;
  z-index: -1;
  top: 0;
  -webkit-transition: opacity 0.5s ease-in;
  -moz-transition: opacity 0.5s ease-in;
  -ms-transition: opacity 0.5s ease-in;
  -o-transition: opacity 0.5s ease-in;
  transition: opacity 0.5s ease-in;

  .image-focus-icon {
    color: $white;
    font-size: 1.5em;
    position: absolute;
    top: 5%;
    right: 5%;
    transition-property: color, transform;
    -webkit-transition: 0.2s ease-out;
    -moz-transition: 0.2s ease-out;
    -ms-transition: 0.2s ease-out;
    -o-transition: 0.2s ease-out;
    transition: 0.2s ease-out;

    @media screen and (min-width: 992px) {
      right: 2%;
    }

    &:hover {
      color: $light_gray;
      transform: scale(1.1);
    }
  }

  img {
    width: 90%;
    height: auto;

    @media screen and (min-width: 1200px) {
      width: 80%;
    }

    @media screen and (min-width: 1400px) {
      width: 70%;
    }
  }

  .loader {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    position: relative;
    animation: rotate 1s linear infinite;
  }
  .loader::before,
  .loader::after {
    content: "";
    box-sizing: border-box;
    position: absolute;
    inset: 0px;
    border-radius: 50%;
    border: 5px solid $white;
    animation: prixClipFix 2s linear infinite;
  }
  .loader::after {
    transform: rotate3d(90, 90, 0, 180deg);
    border-color: #000f66;
  }

  @keyframes rotate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes prixClipFix {
    0% {
      clip-path: polygon(50% 50%, 0 0, 0 0, 0 0, 0 0, 0 0);
    }
    50% {
      clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 0, 100% 0, 100% 0);
    }
    75%,
    100% {
      clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 100% 100%, 100% 100%);
    }
  }
}

.image-focus.show-active {
  opacity: 1;
  z-index: 9999;
}
