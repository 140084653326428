.error-container {
  min-height: 76vh;
  margin-top: 84px;

  .error-column {
    position: relative;
    h2 {
      font-size: 3.5em;
      font-weight: 500;
      margin-top: 60px;

      @media screen and (min-width: 768px) {
        font-size: 4.2em;
        margin-top: 75px;
      }
    }

    p {
      font-size: 0.9em;

      @media screen and (min-width: 768px) {
        font-size: 1.1em;
      }
    }

    h3.error-fof {
      font-size: 3em;
      font-weight: 700;
      font-family: $titles-font;
      position: absolute;
      top: 0;
      right: 5%;

      @media screen and (min-width: 500px) {
        right: 15%;
      }
      @media screen and (min-width: 768px) {
        font-size: 4em;
      }
    }

    .btn {
      text-transform: uppercase;
      font-size: 0.8em;
      border-radius: 0;
    }
  }
}
