.bio-row-home {
  margin-top: 64px;
}

.bio-left-container,
.bio-right-container {
  width: 97%;
  border-radius: $main_radius;
  -webkit-box-shadow: 5px 5px 11px -8px rgba(121, 121, 121, 1);
  -moz-box-shadow: 5px 5px 11px -8px rgba(121, 121, 121, 1);
  box-shadow: 5px 5px 11px -8px rgba(121, 121, 121, 1);
}

.bio-left-container {
  height: 400px;
  background-image: url("../../../assets/images/Photos/Bio/xBio2LowRes.jpg");

  background-size: cover;
  background-position: center top;

  @media screen and (min-width: 400px) {
    height: 500px;
  }
  @media screen and (min-width: 500px) {
    height: 600px;
  }
  @media screen and (min-width: 768px) {
    height: 750px;
  }
  @media screen and (min-width: 992px) {
    height: 800px;
  }
}

.bio-right-container {
  background-color: $form-bg;

  @media screen and (min-width: 992px) {
    height: 800px;
  }

  h3 {
    font-size: 2.2em;
    font-weight: 600;
    font-family: $titles-font;
  }

  p {
    font-size: 1.1em;
    font-weight: 500;
    font-family: $titles-font;
  }

  .bio-slice-p {
    font-size: 0.85em;
    font-weight: 400;
    font-family: $main-font;
    margin-top: 26px;

    @media screen and (min-width: 992px) {
      margin-top: 16px;
    }

    @media screen and (min-width: 1200px) {
      margin-top: 22px;
    }

    @media screen and (min-width: 1400px) {
      font-size: 0.9em;
    }
  }

  .bio-slice-p.italiano {
    @media screen and (min-width: 992px) {
      margin-top: 14px;
      font-size: 0.84em;
    }

    @media screen and (min-width: 1200px) {
      margin-top: 22px;
    }

    @media screen and (min-width: 1400px) {
      font-size: 0.9em;
    }
  }

  .learn-more-btn {
    font-size: 0.75em;
    border-radius: 0;
    margin-top: 30px;
    text-transform: uppercase;
  }
}
