.single-play,
.contact-row {
  height: 800px;
  width: 97%;

  @media screen and (min-width: 1200px) {
    height: 1000px;
  }
}

.single-play {
  background-image: url("../../../assets/images/Photos/Block/Block-long2.jpg");
  background-position: center top;
  background-size: cover;
  border-radius: $main_radius;
  -webkit-box-shadow: 5px 5px 11px -8px rgba(121, 121, 121, 1);
  -moz-box-shadow: 5px 5px 11px -8px rgba(121, 121, 121, 1);
  box-shadow: 5px 5px 11px -8px rgba(121, 121, 121, 1);

  .my-next-play {
    color: $white;
    margin: 50px 35px;

    @media screen and (min-width: 476px) {
      margin: 50px 75px;
    }

    @media screen and (min-width: 768px) {
      margin: 50px 85px;
    }

    @media screen and (min-width: 992px) {
      margin: 50px 35px;
    }

    @media screen and (min-width: 1200px) {
      margin: 50px 55px;
    }

    h4 {
      font-size: 1.8em;
      font-weight: 700;
      font-family: $titles-font;
      margin-bottom: 20px;

      @media screen and (min-width: 400px) {
        font-size: 2.4em;
      }

      @media screen and (min-width: 768px) {
        font-size: 2.7em;
      }

      @media screen and (min-width: 992px) {
        font-size: 2.4em;
      }

      @media screen and (min-width: 1200px) {
        font-size: 2.6em;
      }

      @media screen and (min-width: 1400px) {
        font-size: 2.8em;
      }
    }

    h6 {
      font-size: 1.2em;
      font-weight: 500;

      @media screen and (min-width: 768px) {
        font-size: 1.5em;
      }
    }

    .next-play-icon {
      font-size: 1em;
      @media screen and (min-width: 768px) {
        font-size: 1.1em;
      }
    }

    p {
      font-size: 0.8em;
      white-space: nowrap;

      @media screen and (min-width: 476px) {
        font-size: 0.9em;
      }

      @media screen and (min-width: 576px) {
        font-size: 1em;
      }
    }
  }
}

.contact-row {
  @media screen and (max-width: 991px) {
    height: 950px;
  }

  .contact-img-column {
    height: 40%;
    @media screen and (min-width: 992px) {
      height: 50%;
    }
  }

  .contact-form-column {
    height: 60%;
    @media screen and (min-width: 992px) {
      height: 50%;
    }
  }

  .contact-form,
  .contact-img {
    width: 100%;
    height: 97%;
    background-color: $form_bg;
    border-radius: $main_radius;
    -webkit-box-shadow: 5px 5px 11px -8px rgba(121, 121, 121, 1);
    -moz-box-shadow: 5px 5px 11px -8px rgba(121, 121, 121, 1);
    box-shadow: 5px 5px 11px -8px rgba(121, 121, 121, 1);
  }

  .contact-img {
    background-image: url("../../../assets/images/Photos/Block/Block-small.jpg");
    background-position: center;
    background-size: cover;
  }
}

// Contact form

.contact-form {
  .actual-form {
    h2 {
      font-size: 2em;
      font-family: $titles-font;
      margin-top: 25px;

      @media screen and (min-width: 992px) {
        margin-top: 5px;
      }

      @media screen and (min-width: 1200px) {
        margin-top: 25px;
      }
    }

    label {
      @media screen and (min-width: 992px) {
        font-size: 0.8em;
      }
      @media screen and (min-width: 1200px) {
        font-size: 1em;
      }
    }

    .form-control {
      font-size: 0.9em;
      background-color: $form_bg;
      border: none;
      border-radius: 0;
      border-bottom: 1px solid $light_gray;

      @media screen and (min-width: 992px) {
        font-size: 0.8em;
      }

      @media screen and (min-width: 1200px) {
        font-size: 0.9em;
      }
    }

    textarea {
      margin-top: 15px;
      @media screen and (min-width: 992px) {
        margin-top: 0;
      }
    }

    .btn {
      margin-top: 45px;
      font-size: 0.8em;
      border-radius: 0;

      @media screen and (min-width: 992px) {
        margin-top: 20px;
      }

      @media screen and (min-width: 1200px) {
        margin-top: 26px;
      }
    }
  }
}
