.footer-logo {
  width: 180px;
}

.footer-row {
  color: lightgray;
  background-color: black;
  text-transform: uppercase;

  h6 {
    margin-bottom: 15px;
    font-weight: 600;

    @media screen and (min-width: 992px) {
      margin-bottom: 35px;
    }
  }

  .footer-recap {
    .list-group {
      font-size: 0.85em;
      border: none;
      background-color: inherit;

      @media screen and (min-width: 992px) {
        font-size: 0.95em;
      }

      a {
        text-decoration: none;
      }

      .list-group-item {
        color: lightgray;
        background-color: inherit;
        -webkit-transition: color 0.2s ease-out;
        -moz-transition: color 0.2s ease-out;
        -ms-transition: color 0.2s ease-out;
        -o-transition: color 0.2s ease-out;
        transition: color 0.2s ease-out;

        &:hover {
          color: gray;
        }
      }
    }
  }

  .footer-contacts {
    h6 {
      @media screen and (max-width: 767px) {
        margin-top: 25px;
      }
    }
    .list-group {
      font-size: 0.85em;
      border: none;
      background-color: inherit;

      @media screen and (min-width: 992px) {
        font-size: 0.95em;
      }

      a {
        text-decoration: none;
      }

      .list-group-item {
        color: lightgray;
        background-color: inherit;
        white-space: nowrap;
        -webkit-transition: color 0.2s ease-out;
        -moz-transition: color 0.2s ease-out;
        -ms-transition: color 0.2s ease-out;
        -o-transition: color 0.2s ease-out;
        transition: color 0.2s ease-out;

        &:hover {
          color: gray;
        }
      }

      .list-email {
        text-transform: lowercase;
        text-decoration: underline;
      }
    }
  }

  .footer-location {
    h6 {
      @media screen and (max-width: 767px) {
        margin-top: 35px;
      }

      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }

    img {
      width: 125px;
    }
  }
}
