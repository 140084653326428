.home-container {
  background-image: url("../../../assets/images/Photos/Bg/xBg5.jpg");
  background-size: cover;
  background-position: center top;
  background-repeat: no-repeat;
  background-attachment: scroll;
  position: static;
  overflow: hidden;
  margin-top: 66px;

  .home-second-container {
    min-height: 100vh;
    color: $white;

    h1 {
      font-size: 3.5em;
      font-weight: 700;
      font-family: $titles-font;

      @media screen and (min-width: 768px) {
        font-size: 3.7em;
      }

      @media screen and (min-width: 992px) {
        font-size: 3.8em;
      }

      @media screen and (min-width: 1200px) {
        font-size: 4em;
      }
    }

    p {
      font-family: $titles-font;

      @media screen and (min-width: 768px) {
        font-size: 1.1em;
      }

      @media screen and (min-width: 992px) {
        font-size: 1.2em;
      }

      @media screen and (min-width: 1200px) {
        font-size: 1.3em;
      }
    }

    .biography-slice {
      font-family: $main-font;
      font-size: 0.9em;
      font-weight: 300;

      @media screen and (max-width: 576px) {
        width: 300px;
      }

      @media screen and (max-width: 476px) {
        width: 280px;
      }

      @media screen and (max-width: 440px) {
        width: 260px;
      }

      @media screen and (max-width: 390px) {
        width: 240px;
      }

      @media screen and (min-width: 768px) {
        font-size: 1em;
      }

      @media screen and (min-width: 992px) {
        font-size: 1.1em;
      }

      @media screen and (min-width: 1200px) {
        font-size: 1.2em;
      }
    }

    .about-me-btn {
      border-radius: 0;
      font-size: 0.85em;
    }
  }
}
