.off-canvas-nav {
  color: white;
  background-color: black;

  .offcanvas-header {
    position: relative;

    button.btn-close {
      position: absolute;
      top: 25px;
      right: 25px;
      background-image: none;
    }

    button.btn-close {
      transform: scale(2.2);
      padding: 0;
      background-image: url("../../assets/images/Photos/Others/close-circle-svgrepo-com.svg");

      &:focus {
        box-shadow: none;
      }
    }
  }

  .off-canvas-logo {
    width: 175px;
  }

  .off-title {
    font-weight: 600;
    font-size: 1.8em;
  }

  hr {
    width: 90%;
    margin: 10px 0 0 0;
  }

  .offcanvas-body {
    color: $light_gray;

    h6 {
      font-weight: 600;
    }

    .off-canvas-link-to {
      color: inherit;
      text-decoration: none;
      p {
        color: $light_gray;
        transition-property: color, text-decoration;
        -webkit-transition: 0.2s ease-out;
        -moz-transition: 0.2s ease-out;
        -ms-transition: 0.2s ease-out;
        -o-transition: 0.2s ease-out;
        transition: 0.2s ease-out;

        &:hover {
          color: $white;
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }

    .off-canvas-address {
      text-transform: uppercase;
      font-size: 1.2em;
    }

    .off-canvas-map {
      width: 150px;
    }

    .opera-base-icon-offcanvas {
      width: 30px;
      height: 30px;
      -webkit-transition: transform 0.2s ease-in-out;
      -moz-transition: transform 0.2s ease-in-out;
      -ms-transition: transform 0.2s ease-in-out;
      -o-transition: transform 0.2s ease-in-out;
      transition: transform 0.2s ease-in-out;

      #svg-to-fill-offcanvas {
        fill: $white;
        -webkit-transition: fill 0.2s ease-in-out;
        -moz-transition: fill 0.2s ease-in-out;
        -ms-transition: fill 0.2s ease-in-out;
        -o-transition: fill 0.2s ease-in-out;
        transition: fill 0.2s ease-in-out;
      }

      &:hover {
        transform: scale(1.05);
        cursor: pointer;

        #svg-to-fill-offcanvas {
          fill: #9e9e9e;
        }
      }
    }

    .off-canvas-social-icon {
      color: $white;
      font-size: 1.5em;
      transition-property: color, transform;
      -webkit-transition: 0.2s ease-in-out;
      -moz-transition: 0.2s ease-in-out;
      -ms-transition: 0.2s ease-in-out;
      -o-transition: 0.2s ease-in-out;
      transition: 0.2s ease-in-out;

      &:hover {
        transform: scale(1.05);
        color: rgb(158, 158, 158);
        cursor: pointer;
      }
    }
  }
}
