.my-navbar {
  background-color: $black;

  .nav-logo {
    width: 115px;
  }

  .navbar-collapse {
    @media screen and (min-width: 992px) {
      justify-content: flex-end;
    }
  }

  .lang-dropdown {
    color: $white;
    background-color: inherit;
    border: none;

    .dropdown-toggle {
      border: 1px solid gray;

      span {
        color: $white;
        -webkit-transition: color 0.2s ease-in-out;
        -moz-transition: color 0.2s ease-in-out;
        -ms-transition: color 0.2s ease-in-out;
        -o-transition: color 0.2s ease-in-out;
        transition: color 0.2s ease-in-out;
      }

      &::after {
        -webkit-transition: color 0.2s ease-in-out;
        -moz-transition: color 0.2s ease-in-out;
        -ms-transition: color 0.2s ease-in-out;
        -o-transition: color 0.2s ease-in-out;
        transition: color 0.2s ease-in-out;
        color: $white;
      }

      &:hover {
        span {
          color: $light_gray;
        }

        &::after {
          color: $light_gray;
        }
      }
    }

    .dropdown-menu {
      position: absolute;
      background-color: $white;
      --bs-dropdown-min-width: 7.5rem;
    }
    .dropdown-item {
      text-transform: uppercase;

      &:active {
        background-color: $black;
        color: $white;
      }
    }
  }

  a.nav-link {
    color: $white;
    text-transform: uppercase;
    font-size: 0.85em;
    border-bottom: 1px solid rgba(255, 255, 255, 0);
    border-color: none;
    transition-property: color, background-color;
    -webkit-transition: 0.2s ease-in-out;
    -moz-transition: 0.2s ease-in-out;
    -ms-transition: 0.2s ease-in-out;
    -o-transition: 0.2s ease-in-out;
    transition: 0.2s ease-in-out;

    &:hover {
      color: $light_gray;
      @media screen and (min-width: 992px) {
        border-color: $white;
      }
    }
  }

  a.disabled-link {
    color: gray !important;
    border-color: rgba(255, 255, 255, 0) !important;
  }

  a.nav-link.active-bold {
    color: $white;
    font-weight: 700;
  }

  .nav-link-icon {
    color: $light_gray;
  }

  hr {
    margin: 5px 0;
    color: rgb(245, 245, 245);
  }

  .off-canvas-btn {
    background-color: inherit !important;
    border: none !important;
    -webkit-transition: transform 0.3s ease-in-out;
    -moz-transition: transform 0.3s ease-in-out;
    -ms-transition: transform 0.3s ease-in-out;
    -o-transition: transform 0.3s ease-in-out;
    transition: transform 0.3s ease-in-out;

    .off-canvas-icon {
      color: $white;
      transition-property: color, transform;
      -webkit-transition: 0.3s ease-in-out;
      -moz-transition: 0.3s ease-in-out;
      -ms-transition: 0.3s ease-in-out;
      -o-transition: 0.3s ease-in-out;
      transition: 0.3s ease-in-out;
    }

    &:hover {
      .off-canvas-icon {
        transform: scale(1.1);
      }
    }
  }

  button.navbar-toggler {
    .navbar-toggler-icon {
      background-image: none;
    }

    .navbar-toggler-icon {
      background-image: url("../../assets/images/Photos/Others/menu-svgrepo-com.svg");
      -webkit-transition: box-shadow 0.2s ease-in-out;
      -moz-transition: box-shadow 0.2s ease-in-out;
      -ms-transition: box-shadow 0.2s ease-in-out;
      -o-transition: box-shadow 0.2s ease-in-out;
      transition: box-shadow 0.2s ease-in-out;

      &:hover {
        -webkit-box-shadow: 0px 0px 31px 0px rgba(212, 212, 212, 0.52);
        -moz-box-shadow: 0px 0px 31px 0px rgba(212, 212, 212, 0.52);
        box-shadow: 0px 0px 31px 0px rgba(212, 212, 212, 0.52);
      }
    }
  }
}
