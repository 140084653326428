.social-row {
  margin-bottom: 32px;
  .social-icon-container {
    height: 150px;

    @media screen and (min-width: 576px) {
      height: 200px;
    }

    @media screen and (min-width: 768px) {
      height: 250px;
    }

    @media screen and (min-width: 992px) {
      height: 300px;
    }

    @media screen and (min-width: 1400px) {
      height: 350px;
    }

    .opera-base-icon {
      width: 50px;
      height: 50px;
      -webkit-transition: transform 0.2s ease-in-out;
      -moz-transition: transform 0.2s ease-in-out;
      -ms-transition: transform 0.2s ease-in-out;
      -o-transition: transform 0.2s ease-in-out;
      transition: transform 0.2s ease-in-out;

      @media screen and (min-width: 576px) {
        width: 60px;
        height: 60px;
      }

      #svg-to-fill {
        fill: #c4c4c4;
        -webkit-transition: fill 0.2s ease-in-out;
        -moz-transition: fill 0.2s ease-in-out;
        -ms-transition: fill 0.2s ease-in-out;
        -o-transition: fill 0.2s ease-in-out;
        transition: fill 0.2s ease-in-out;
      }

      &:hover {
        transform: scale(1.1);
        cursor: pointer;

        #svg-to-fill {
          fill: $black;
        }
      }
    }

    .social-large-icon {
      font-size: 3.5em;
      color: $light_gray;
      -webkit-transition: 0.2s;
      -moz-transition: 0.2s;
      transition-property: color, transform;
      -webkit-transition: 0.2s ease-in-out;
      -moz-transition: 0.2s ease-in-out;
      -ms-transition: 0.2s ease-in-out;
      -o-transition: 0.2s ease-in-out;
      transition: 0.2s ease-in-out;

      @media screen and (min-width: 576px) {
        font-size: 4em;
      }

      @media screen and (min-width: 992px) {
        font-size: 5em;
      }
      &:hover {
        cursor: pointer;
        transform: scale(1.1);
        color: $black;
      }
    }
  }

  // .social-icon-container.left,
  // .social-icon-container.middle {
  //   border-left: 1px solid $light_gray;
  // }

  // .social-icon-container.right,
  // .social-icon-container.middle {
  //   border-right: 1px solid $light_gray;
  // }
}
