// fonts:

$main-font: "Montserrat", sans-serif;
$titles-font: "Merriweather", serif;

// colors:

$white: #fff;
$black: #000;
$form_bg: #f0f0f0;
$light_gray: #d3d3d3;

// styles:

$main_radius: 25px;
