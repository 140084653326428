.news-row {
  margin-bottom: 18px;

  .news-title-column {
    h3 {
      font-size: 2.2em;
      font-weight: 500;
      font-family: $titles-font;
    }
  }

  .news-swiper-container {
    .news-swiper {
      .news-card {
        width: 90%;
        max-width: 350px;
        border-color: gray;
        border-radius: 10px;
        -webkit-box-shadow: inset -2px 2px 14px -8px rgba(198, 198, 198, 1);
        -moz-box-shadow: inset -2px 2px 14px -8px rgba(198, 198, 198, 1);
        box-shadow: inset -2px 2px 14px -8px rgba(198, 198, 198, 1);

        @media screen and (min-width: 576px) {
          width: 100%;
        }

        @media screen and (min-width: 768px) {
          width: 90%;
        }

        @media screen and (min-width: 1200px) {
          width: 100%;
        }

        @media screen and (min-width: 2000px) {
          width: 100%;
        }

        .card-header {
          background-color: inherit;
          border: none;

          .icon-news-container {
            width: 50px;
            height: 50px;
            background-color: $black;
            border: 5px solid rgba(172, 172, 172, 0.548);
            border-radius: 50px;

            .news-icon {
              color: $white;
            }
          }

          .news-title-container {
            width: 100%;
            h4 {
              font-size: 1em;
              font-weight: 600;
              text-transform: uppercase;
              font-family: $titles-font;
              margin: 0;
              position: relative;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;

              @media screen and (min-width: 400px) {
                font-size: 1.2em;
              }

              @media screen and (min-width: 576px) {
                font-size: 0.9em;
              }

              @media screen and (min-width: 768px) {
                font-size: 1.1em;
              }

              @media screen and (min-width: 992px) {
                font-size: 1.3em;
              }
            }

            p {
              font-size: 12px;
              font-style: italic;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;

              @media screen and (min-width: 992px) {
                font-size: 13px;
              }
            }

            .badge {
              position: absolute;
              right: 0;
              top: -50px;
              font-size: 12px;
              font-weight: 400;
              font-family: $main-font;
              margin: 0;
            }
          }
        }

        .card-body {
          border: none;
          border-color: inherit;
          background-color: inherit;

          div {
            border-bottom: 1px solid rgba(211, 211, 211, 0.555);
            padding-bottom: 5px;

            h6 {
              font-size: 0.8em;
              font-weight: 700;
              text-transform: uppercase;

              @media screen and (min-width: 345px) {
                font-size: 0.9em;
              }

              @media screen and (min-width: 576px) {
                font-size: 0.8em;
              }

              @media screen and (min-width: 768px) {
                font-size: 0.9em;
              }

              @media screen and (min-width: 992px) {
                font-size: 1em;
              }
            }

            p {
              font-size: 11px;
              margin-bottom: 0;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;

              @media screen and (min-width: 345px) {
                font-size: 13px;
              }

              @media screen and (min-width: 576px) {
                font-size: 11px;
              }

              @media screen and (min-width: 768px) {
                font-size: 13px;
              }
            }
          }
        }

        .card-footer {
          border: none;
          background-color: inherit;
          .btn {
            width: 100%;
            border-radius: 7px !important;
            font-size: 12px;
            border-radius: 0.375rem;
            padding: 12px 24px;
          }
        }
      }
    }
  }

  .swiper-wrapper {
    padding-bottom: 35px;
  }

  .swiper-horizontal > .swiper-pagination-bullets,
  .swiper-pagination-bullets.swiper-pagination-horizontal,
  .swiper-pagination-custom,
  .swiper-pagination-fraction {
    transform: translateY(10px);
  }
}
.modal-backdrop.show {
  opacity: 0.8 !important;
}

.news-modal {
  padding: 0 !important;

  .modal-dialog {
    display: flex;
    justify-content: center;
    align-items: center;
    .modal-content {
      @media screen and (min-width: 376px) {
        width: 90vw;
        max-width: 450px;
      }

      @media screen and (min-width: 992px) {
        width: 90vw;
        max-width: 600px;
      }

      @media screen and (min-width: 1200px) {
        width: 90vw;
        max-width: 700px;
      }

      @media screen and (min-width: 2000px) {
        width: 90vw;
        max-width: 850px;
      }

      .modal-header {
        background-color: rgba(236, 236, 234, 0.705);
        .modal-title {
          font-size: 1.5em;
          font-weight: 700;
          font-family: $titles-font;
          text-transform: uppercase;

          @media screen and (min-width: 992px) {
            font-size: 1.8em;
          }

          @media screen and (min-width: 1200px) {
            font-size: 2em;
          }

          @media screen and (min-width: 2000px) {
            font-size: 2.3em;
          }
        }
      }

      .modal-body {
        font-size: 12px;
        font-weight: 400;
        padding-top: 46px !important;
        position: relative;

        @media screen and (min-width: 400px) {
          font-size: 13px;
        }

        @media screen and (min-width: 992px) {
          font-size: 16px;
          padding-top: 76px !important;
        }

        @media screen and (min-width: 992px) {
          font-size: 17px;
        }

        @media screen and (min-width: 2000px) {
          font-size: 20px;
        }

        .badge {
          font-size: 12px;
          position: absolute;
          top: 26px;
          left: 16px;

          @media screen and (min-width: 992px) {
            font-size: 14px;
          }

          @media screen and (min-width: 2000px) {
            font-size: 16px;
          }
        }
      }

      .modal-footer {
        color: $white;
        font-weight: 600;
        background-color: $black;
        text-transform: uppercase;
        max-height: 49px;
        transition-property: background-color, font-size, color;
        -webkit-transition: 0.25s ease-out;
        -moz-transition: 0.25s ease-out;
        -ms-transition: 0.25s ease-out;
        -o-transition: 0.25s ease-out;
        transition: 0.25s ease-out;

        &:focus {
          font-size: 18px;
          color: $black;
          background-color: $white;
          cursor: pointer;
        }

        &:focus-visible {
          font-size: 18px;
          color: $black;
          background-color: $white;
          cursor: pointer;
        }

        &:hover {
          font-size: 18px;
          color: $black;
          background-color: $white;
          cursor: pointer;
        }
      }
    }
  }
}
